/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {
  Breadcrumb,
} from '@cleverrealestate/clever-components';
import BasicModal from '@cleverrealestate/clever-components-v2/dist/components/BasicModal';
import NewBottomCTA from '@cleverrealestate/clever-components-v2/dist/components/NewBottomCTA';
import {
  styleConfig,
  useUserIPGrabber,
  useAnalytics,
  CA_EventLocation,
} from '@cleverrealestate/clever-helpers';
import SEO from '../components/Seo';
import Layout from '../components/layout';
import MainSiteBg from '../images/img_comps/backgrounds/MainSiteBg';
import withMUI from '../utils/MUI.utils';
import renderContent from '../utils/inline-utils-v3';

const useStyles = makeStyles(theme => ({
  whiteContent: {
    backgroundColor: 'white',
  },
  maxWidthContainer: {
    display: 'flex',
    justifyContent: 'center',
    scrollBehavior: 'smooth',
    marginTop: '40px',
  },
  setWidth: {
    width: 'calc(100% - 100px)',
    display: 'flex',
    maxWidth: '1194.63px',
    marginLeft: '50px',
    marginRight: '50px',
    marginBottom: '50px',
    flexDirection: 'row',
    '@media screen and (max-width: 1300px)': {
      width: 'calc(100% - 50px)',
      marginLeft: '0',
      marginRight: '0',
    },
  },
  defaultJourneyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& h2': {
      margin: '20px 0',
      fontSize: '1.7rem',
      color: '#5a5a5a',
    },
    '& h3': {
      color: '#5a5a5a',
    },
    '& h4': {
      color: '#5a5a5a',
    },
    '& $InlineCTABody > h3': {
      color: 'white',
    },
  },
  heading: {
    marginTop: '70px',
    '@media screen and (min-width: 768px)': {
      marginTop: '50px',
    },
  },
  breadcrumbswrapper: {
    backgroundColor: '#367CB2',
    justifyContent: 'center',
    display: 'flex',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    zIndex: '10',
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      backgroundPosition: 'center',
    },
    '& h1': {
      maxWidth: '1194.63px',
      flexDirection: 'row',
      color: 'white',
      fontSize: '1.5rem',
      margin: '60px auto 0',
      paddingBottom: '20px',
      borderBottom: '1px solid white',
      textTransform: 'uppercase',
      zIndex: '10',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        position: 'absolute',
        right: '40%',
        bottom: '50px',
      },
    },
  },
  textContentWrapper: {
    marginLeft: '30px',
    width: '100%',
    '@media screen and (max-width: 1200px)': {
      marginLeft: '0',
    },
  },
  mainSection: {
    '& p': {
      fontSize: '1rem',
    },
    '& a': {
      textDecoration: 'none',
      cursor: 'pointer',
      color: theme.mixins.color.cleverBlue,
      paddingLeft: '40px',
      [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
        padding: '0',
      },
    },
    '& iframe': {
      width: '100%',
      maxWidth: '100%',
    },
    '& img': {
      width: '100%',
      maxWidth: '100%',
      maxHeight: 'auto',
      height: 'auto',
    },
    '& ul': {
      padding: '0',
    },
    '& li': {
      position: 'relative',
      listStyle: 'none',
    },
    '& h2': {
      fontSize: '1.7rem',
    },
    '& hr': {
      border: '.5px solid #dbd9d9',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        border: '1px solid #dbd9d9',
      },
    },
  },
  navWhite: {
    backgroundColor: 'white',
  },
  blogTopContainerElementTop: {
    position: 'relative',
  },
  bgWrapper: {
    position: 'absolute',
    maxWidth: '100%',
    overflow: 'hidden',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      position: 'relative',
    },
  },
  scrollBodyStyle: {
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      maxWidth: '100%',
      margin: '0px',
      height: '100%',
    },
  },
}));

const LegalPageTemplate = ({ pageContext }) => {
  const classes = useStyles();
  useUserIPGrabber();

  const {
    seo,
    title,
    slug,
    renderContext,
    modified_at,
  } = pageContext;
  const [modalOpen, setModalOpen] = useState(false);
  const [fireEvent, actions, contextCreators] = useAnalytics();

  const handleLinkCTAClick = () => {
    setModalOpen(true);
    contextCreators.inlineLinkModal(CA_EventLocation.Inline);
    fireEvent(actions.Step1);
  };

  if (typeof window !== 'undefined') {
    window.globalClick = () => {
      handleLinkCTAClick();
    };
  }

  const breadcrumbs = [
    {
      link: '/',
      title: 'Home',
    },
    {
      link: `/${slug}/`,
      title: 'Real Estate FAQs',
    },
  ];

  const publishDateMeta = [
    {
      name: 'article:modified_time',
      content: modified_at,
    },
    {
      name: 'og:updated_time',
      content: modified_at,
    },
  ];

  return (
    <Layout
      colorTransitionStart={-1}
      shadowTransitionStart={50}
      colorTransitionLength={1}
      shadowTransitionLength={125}
      extraClasses={classes.whiteContent}
      navClasses={classes.navWhite}
      isWhite
    >
      <SEO
        pathname={`/${slug}/`}
        title={seo.title}
        publishDateMeta={publishDateMeta}
        description={seo.meta_description}
      />
      <div className={classes.heading}>
        <div className={classes.maxWidth}>
          <div className={classes.blogTopContainerElementTop}>
            <div className={classes.breadcrumbswrapper}>
              <Breadcrumb breadcrumbs={breadcrumbs} isLocal />
            </div>
          </div>
          <div className={classes.titleWrapper}>
            <div className={classes.bgWrapper}>
              <MainSiteBg />
            </div>
            <Typography color="inherit" variant="h1">
              {title}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.maxWidthContainer}>
        <div className={classes.setWidth}>
          <div className={classes.textContentWrapper}>
            <section className={classes.defaultJourneyContainer}>
              <article id="article-content" className={classes.mainSection}>
                { renderContent(renderContext.html, renderContext.context) }
              </article>
            </section>
          </div>
        </div>
      </div>
      {
        modalOpen ? createPortal((
          <BasicModal
            closeDialog={() => setModalOpen(false)}
          />
        ), document.body) : null
      }
      <NewBottomCTA />
    </Layout>
  );
};

LegalPageTemplate.propTypes = {
  pageContext: PropTypes.any,
};

LegalPageTemplate.defaultProps = {
  pageContext: {},
};

export default withMUI(LegalPageTemplate);
